import { Grid, GridCell, GridColumn} from '@progress/kendo-react-grid'
import { ClickOutside } from 'components/click-outside/component'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import isNil from 'lodash/isNil'
import React from 'react'
import { Button, Col, Row} from 'reactstrap'
import { subscribersFormatDate } from 'shared_server_client/dates'
import { EditButtonLight } from '../../components/icons/lightedit'
import './styles.css'

type Subscriber = any

interface SubscriberIdProps {
  subscriberId: string,
}

interface SubscriberDetailsState {
  communityId: string,
  drop?: boolean
  editMode: boolean,
  isEditing: boolean,
  email: string,
  first_name: string,
  last_name: string,
  segment_code?: string,
  segments?: any[],
}

interface SubscriberDetailProps extends GenericRedux.AllComponentProps<Subscriber> {
  communityTimeZone: string
  subscriberId: string
  onLoad: any
}

class SubscriberDetailComponent extends RasaReactComponent<SubscriberDetailProps, SubscriberDetailsState> {

  constructor(props: SubscriberDetailProps) {
    super(props, 'rasaSubscriber')

    this.state = {
      communityId: '',
      drop: false,
      editMode: false,
      isDirty: false,
      isLoading: true,
      isSaving: false,
      isEditing: false,
      email: '',
      first_name: '',
      last_name: '',
      segments: [],
    }
  }

  public componentDidMount() {
    this.loadOnRouting(this.props.subscriberId, '/contacts/detail')
  }

  public loadRecord(communityId: string, recordId: any): Promise<boolean> {
    this.setState({
      isLoading: true,
    })
    return super.loadRecord(communityId, recordId).then((result) => {
      this.props.onLoad(this.props.data)
      this.setState({
        segment_code: this.props.data.segment_code,
      })
      return result
    })
  }

  public render = () => {
    return this.state.isLoading ?
    <div className="loader-wrapper">
      <div className="balls">
        <Loading size="64" />
      </div>
    </div>
    : (!this.state.isLoading && !this.props.data.id)
    ?
    <div>
        <h3>Something has gone wrong</h3>
      </div>
    :
    <div className="subscriber-details">
      <Row className="top-header">
        <Col md="6">
          <div className="header-left">
            <div className="text">
              <div className="text-col">
                <div className="header-title">CONTACT DETAILS</div>
                <h4>
                  {`${this.props.data.first_name || ''} ${this.props.data.last_name || ''}`}
                </h4>
                <div>
                  {this.props.data.email || ''}
                  {this.props.data.email &&
                  <p>
                    {this.props.data.first_name} is&nbsp;
                      <span><strong>
                        {this.props.data.is_archived
                        ? 'Archived'
                        : this.isSubscribed() ? 'Subscribed' : 'Unsubscribed'}
                      </strong></span>
                    <br/>
                    {this.lastActiveDate() &&
                      <strong>
                        Last active on {subscribersFormatDate(this.lastActiveDate(), this.props.communityTimeZone)}
                      </strong>}
                  </p>}
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col md="2">
          <div className="header-right">
            <ClickOutside handleClickOutside={() => this.setState({drop: false})}>
              {!this.props.data.is_archived &&
              <div className="subscription-option-button-container">
                {this.isSubscribed()
                ? <Button
                    className="subscription-option-button"
                    onClick={() => {
                      this.propertyChanged('is_subscribed', 0)
                      this.propertyChanged('unsubscribe_reason', 'UI')
                      this.saveRecord(this.props.data.id)
                    }}
                  >Unsubscribe
                  </Button>
                : <Button
                    className="subscription-option-button"
                    onClick={() => {
                      this.propertiesChanged({
                        is_subscribed: 1,
                        is_active: 1,
                      })
                      this.saveRecord(this.props.data.id)
                    }}
                  >Subscribe
                </Button>}
              </div>}
            </ClickOutside>
            <ClickOutside handleClickOutside={() => this.setState({drop: false})}>
              <div className="archiving-option-button-container">
                {this.props.data.is_archived
                ? <Button
                    className="archiving-option-button"
                    onClick={() => {
                      this.propertyChanged('is_archived', 0)
                      this.saveRecord(this.props.data.id)
                    }}
                  >Restore
                  </Button>
                : <Button
                    className="archiving-option-button"
                    onClick={() => {
                      this.propertyChanged('is_archived', 1)
                      this.saveRecord(this.props.data.id)
                    }}
                    >Archive
                  </Button>}
              </div>
            </ClickOutside>
          </div>
        </Col>
      </Row>
      <Row className="info-table">
        <Col md="5">
          <div className="details-left attributes">
            <div className="details-left-content">
              <div className="properties">
              {this.state.isEditing ?
                <p className="saving-button">
                  Saving...
                </p>
                :
                this.state.editMode ?
                  <p className="edit-button-2">
                    <span className="save"
                          onClick={() => {
                            this.setState({isEditing: true}, () => {
                              this.propertyChanged('email', this.state.email)
                              this.propertyChanged('first_name', this.state.first_name)
                              this.propertyChanged('last_name', this.state.last_name)
                              this.propertyChanged('segment_code', this.state.segment_code)
                              this.saveRecord(this.props.data.id).then(() => {
                                this.setState({isEditing: false, editMode: false})
                            })
                            })
                          }
                          }>
                      Save
                    </span>
                    <span className="cancel"
                          onClick={() => {
                            this.setState({editMode: !this.state.editMode})
                          }
                          }>
                      Cancel
                    </span>
                  </p>
                : !this.props.data.is_archived &&
                  <p className="edit-button"
                      onClick={this.initializeEdit}>
                    Edit
                    <EditButtonLight />
                  </p>
                }
                <p className="left-field">E-mail:</p>
                <p className="left-field">First Name:</p>
                <p className="left-field">Last Name:</p>
                {(this.state.segments.length > 0 || this.props.data.segment_code) &&
                  <p className="left-field">Segment:</p>}
              </div>
              <div className="values">
                {this.state.editMode ?
                  <span className="edit-details">
                    <p id="details-margin2"></p>
                    <p><input type="text" value={this.state.email}
                              onChange={(e) => {
                                e.preventDefault()
                                this.setState({email: e.target.value} )
                              }
                              }/></p>
                    <p><input type="text" value={this.state.first_name}
                              onChange={(e) => {
                                e.preventDefault()
                                this.setState({first_name:  e.target.value})
                              }
                              }/></p>
                    <p><input type="text" value={this.state.last_name}
                              onChange={(e) => {
                                e.preventDefault()
                                this.setState({last_name: e.target.value})
                              }
                              }/></p>
                    <p><LoadSegmentCodes segmentCode={this.state.segment_code} setSegmentCode={this.setSegmentCode}
                      setConfigData={this.setSegments} hideLabel={true}></LoadSegmentCodes></p>
                  </span>
                    :
                  <span className="details-view">
                    {!this.props.data.is_archived && <p className="details-margin"></p>}
                    <p>{this.props.data.email || '-'}</p>
                    <p>{this.props.data.first_name}</p>
                    <p>{this.props.data.last_name}</p>
                    <p>{this.props.data.segment_code === '-1' ? null : this.props.data.segment_code}</p>
                  </span>
                }
              </div>
            </div>
          </div>
        </Col>
        <div className="vertical-line"></div>
        <Col md="5" className="attributes">
          <div className="details-left">
              <div className="details-left-content">
              <div className="properties">
                <p>Last Newsletter: </p>
                <p>Last Clicked: </p>
                <p>Last Opened: </p>
                <p>Unique Clicks: </p>
                <p>Unique Opens: </p>
                <p>Created: </p>
                <p>Updated: </p>
              </div>
              <div className="values">
                <p>{subscribersFormatDate(this.props.data.last_newsbrief, this.props.communityTimeZone || '-')} </p>
                <p>{subscribersFormatDate(this.props.data.last_click, this.props.communityTimeZone || '-')} </p>
                <p>{subscribersFormatDate(this.props.data.last_open, this.props.communityTimeZone || '-')} </p>
                <p>{this.props.data.unique_clicks || '-'}</p>
                <p>{this.props.data.unique_opens || '-'}</p>
                <p>{subscribersFormatDate(this.props.data.created, this.props.communityTimeZone || '-')} </p>
                <p>{subscribersFormatDate(this.props.data.updated, this.props.communityTimeZone || '-')}</p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  }

  private setSegmentCode = (code: any) => {
    this.setState({
      segment_code: code.key,
    })
  }

  private setSegments = (segments: any[]) => {
    this.setState({
      segments,
    })
  }

  private isSubscribed = (): boolean => {
    return this.props.data.hasOwnProperty('is_active')
      ? !!this.props.data.is_subscribed && !!this.props.data.is_active
      : !!this.props.data.is_subscribed
  }

  private lastActiveDate = () => {
    const lastOpen = this.props.data.last_open
    const lastClick = this.props.data.last_click
    if (lastOpen && lastClick) {
      return lastOpen > lastClick ? lastOpen : lastClick
    } else {
      return lastOpen || lastClick || ''
    }
  }

  private initializeEdit = () => {
    if (this.state.editMode) {
      this.setState({
        editMode: false,
        email: '',
        first_name: '',
        last_name: '',
      })
    } else {
      this.setState({
        editMode: true,
        email: this.props.data.email,
        first_name: this.props.data.first_name,
        last_name: this.props.data.last_name,
      })
    }
  }
}

const SubscriberDetails: any = GenericRedux.registerNewNestedComponent(
  SubscriberDetailComponent,
  'subscriber_details')

interface Action {
  action_type: string
  created: string
  email_subject?: string
  id: string
  issue_date: string
  title?: string
  url?: string
  useragent?: string
}

interface Topic {
  topic: string
  first_click: string
  last_click: string
}

interface Insights {
  actions: Action[],
  topics: Topic[]
}

type SubscriberInsightsProps = SubscriberIdProps & GenericRedux.AllComponentProps<Insights> & SubscriberDetailProps

class SubscriberInsightsComponent extends RasaReactComponent<SubscriberInsightsProps> {
  constructor(props) {
    super(props, 'rasaSubscriberInsights')

    this.normalizeActionsData = this.normalizeActionsData.bind(this)
    this.normalizeTopicsData = this.normalizeTopicsData.bind(this)
  }

  public componentDidMount() {
    this.loadOnRouting(this.props.subscriberId, '/contacts/detail')
  }

  public render = () => {
    return <div className="last-activity-feed">
      <h6>Last Activity Feed: </h6>
      {this.state.isLoading ?
        <div className="loader-wrapper">
          <div className="balls">
            <Loading size="64" />
          </div>
        </div>
      :
      <div className="grids-container">
        <div className="rasa-data-grid left">
          <Grid
            data={this.normalizeActionsData(this.props.data.actions || [], this.props.communityTimeZone).slice(0, 20)}>
            <GridColumn field="action_type" title="Type" width={100}/>
            <GridColumn field="issue_date" title="Newsletter Date" width={150}/>
            <GridColumn field="created" title="Action Date" width={150}/>
            <GridColumn cell={ActivityTextCell} title="Title/Subject" width={250}/>
          </Grid>
        </div>
        <div className="rasa-data-grid left">
          <Grid data={this.normalizeTopicsData(
              this.props.data.topics || [],
              this.props.communityTimeZone).slice(0, 10)}>
            <GridColumn field="topic" title="Topic" width={150}/>
            <GridColumn field="last_click" title="Last Click" width={150}/>
            <GridColumn field="first_click" title="First Click" width={150}/>
          </Grid>
        </div>
      </div>}

    </div>
  }

  private normalizeActionsData = (actions: Action[] = [], timezone: string) => {
    return actions.map( (action: Action) => {
      return {
        action_type: action.action_type,
        issue_date: new Date(action.issue_date).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
          weekday: 'short',
        }),
        created: subscribersFormatDate(action.created, timezone),
        email_subject: action.email_subject.split('|')[0],
        title: action.title,
        url: action.url,
      }
    })
  }

  private normalizeTopicsData = (topics: Topic[] = [], timezone: string) => {
    return topics.map((t: Topic) => {
      return {
        topic: t.topic,
        first_click: subscribersFormatDate(t.first_click, timezone),
        last_click: subscribersFormatDate(t.last_click, timezone),
      };
    })
  }

}

class ActivityTextCell extends GridCell {
  public render() {
    return <td>
      {this.props.dataItem.action_type === 'open' ?
        this.props.dataItem.email_subject :
          <div className="post-title">
            <a id={`Title-${this.props.dataItem.url}`}
              href={this.props.dataItem.url}
              target="_blank" rel="noopener noreferrer">
              {this.props.dataItem.title || this.props.dataItem.url}
            </a>
          </div>
        }
    </td>
  }
}

const SubscriberInsights: any = GenericRedux.registerNewNestedComponent(
  SubscriberInsightsComponent,
  'subscriber_insights')

interface SubscriberDetailState {
  communityTimeZone: string,
  isLoaded: boolean,
  isSubscribed: boolean,
}
export class SubscriberDetailsContainer extends React.Component<any, SubscriberDetailState> {
  public static contextType = RasaContext

  constructor(props) {
    super(props, '')
    this.state = {
      communityTimeZone: '',
      isLoaded: false,
      isSubscribed: false,
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({ activeCommunity }) => {
      this.setState({communityTimeZone: activeCommunity.data.company_time_zone || 'US/Central'});
    })
  }

  public render() {
    const guid: string = this.context.store.getState().app.params.id

    return isNil(guid) ? (
      <div></div>
    ) : (
      <div className="subscriber-details">
        <Row>
          <SubscriberDetails
            communityTimeZone={this.state.communityTimeZone}
            subscriberId={guid}
            onLoad={(s) => {
              this.setState({
                isLoaded: true,
                isSubscribed: s.is_subscribed,
              })
            }}
          />
        </Row>
        {(this.state.isLoaded && this.state.isSubscribed)
        ? <Row>
            <SubscriberInsights
              communityTimeZone={this.state.communityTimeZone}
              subscriberId={guid}
            />
          </Row>
        : <div></div>
        }
        {/* <Row>
          <div className="day-activity-feed">
            <h6>
              Time of the Day Activity:
            </h6>
          </div>
        </Row> */}
      </div>
    )
  }
}
